import axiosClient from './base'

export const attachUserAndPractice = (
	userId: string,
	practiceId: string,
	userType: string
): Promise<UpdateUserResponse> =>
	axiosClient.put(`/api/users/${userId}/practices/${practiceId}`, { userType })

export const detachUserAndPractice = (
	userId: string,
	practiceId: string
): Promise<UpdateUserResponse> => axiosClient.delete(`/api/users/${userId}/practices/${practiceId}`)

export const updateUser = (userId: string, requestBody: object): Promise<UpdateUserResponse> =>
	axiosClient.put(`/api/users/${userId}`, requestBody)

export const updateUserPassword = (email: string, requestBody: object) =>
	axiosClient.post(`/api/reset-password/${email}`, requestBody)

export const sendPasswordResetEmail = (email: string) =>
	axiosClient.post('/api/send-password-reset-email', { email })

export const getContext = (practiceId?: string) =>
	practiceId
		? axiosClient.get(`/api/context?practiceId=${practiceId}`)
		: axiosClient.get('/api/context')

export const getUserFinancingInfo = (practiceId: number, userId: number, redirectPath?: string) => {
	const isWebAppDomain = window.location.hostname.includes('web.app')
	const isStagingDomain = window.location.hostname.includes('staging')
	const baseUrl = new URL(window.location.href).origin

	const requestBody =
		!redirectPath || isWebAppDomain || isStagingDomain
			? { practiceId, userId }
			: {
					practiceId,
					userId,
					redirectUri: `${baseUrl}/${redirectPath}`
				}

	return axiosClient.post('/api/users/financing', requestBody)
}

export const plaidLinkSaveAch = ({ publicToken, account, practiceId }) =>
	axiosClient.post('/api/plaid/save-account', {
		publicToken,
		account,
		practiceId
	})

export const getMavenUserToken = (practiceId: string) =>
	axiosClient.get(`/api/practices/${practiceId}/maven-user-token`)

export const validateUserEmail = (email: string): Promise<ICheckEmailExistsResponse> =>
	axiosClient.get(`/api/users/${email}`)
