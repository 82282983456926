import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import { getMavenUserToken } from 'apis/user'
import { selectUser } from 'selectors/auth'
import { useCurrentPractice } from 'contexts/PracticeContext'
import { MAVEN_AGI_CHAT_CONFIG } from '../../config'
import { logError } from 'util/logger'

declare global {
	interface Window {
		Maven: any
	}
}

export const MavenChatWidget = () => {
	const user = useSelector(selectUser)
	const { currentPracticeObject } = useCurrentPractice()

	const isInitializing = useRef(false)
	const isChatInitialized = useRef(false)
	const lastPracticeId = useRef<string | null>(null)

	const getPracticeId = () => {
		return (
			currentPracticeObject?.dbid ||
			currentPracticeObject?.id ||
			currentPracticeObject?.stripeAccountId ||
			null
		)
	}

	const initChatWidget = async () => {
		const practiceId = getPracticeId()

		if (!user || isEmpty(currentPracticeObject) || !practiceId) {
			console.log('[Maven Chat Widget]: empty data', { practiceId })
			return
		}

		if (isChatInitialized.current && lastPracticeId.current === practiceId) {
			console.log('[Maven Chat Widget]: already loaded')
			return
		}

		if (window?.Maven?.ChatWidget) {
			isInitializing.current = true

			const { data } = await getMavenUserToken(practiceId).catch((err) => {
				logError(
					err,
					true,
					`[Maven Chat Widget]: failed to get user token for practice ${practiceId}.`
				)
				return { data: null }
			})

			if (!data?.token) {
				console.warn(`[Maven Chat Widget]: won't load without user token`)
				isChatInitialized.current = false
				isInitializing.current = false
				return
			}

			console.log('Loading Maven Chat for practice:', practiceId)

			window.Maven.ChatWidget.load({
				textColor: 'white',
				bgColor: '#5B70FF',
				organizationId: MAVEN_AGI_CHAT_CONFIG.organizationId,
				agentId: MAVEN_AGI_CHAT_CONFIG.agentId,
				horizontalPosition: 'right',
				verticalPosition: 'bottom',
				signedUserData: data.token
			}).then(() => {
				isChatInitialized.current = true
				isInitializing.current = false
				lastPracticeId.current = practiceId
				console.log(`[Maven Chat Widget]: loaded for practice ${practiceId}`)
			})
		}
	}

	const closeChatWidget = () => {
		if (isChatInitialized.current) {
			console.log(`[Maven Chat Widget]: closing chat widget`)

			isChatInitialized.current = false
			window?.Maven?.ChatWidget?.close()
			const chatElements = window.document.querySelectorAll('#maven-chat-widget')
			chatElements.forEach((element) => element.remove())
			isInitializing.current = false
		}
	}

	useEffect(() => {
		const practiceId = getPracticeId()

		if (!practiceId || isInitializing.current) {
			console.log(`[Maven Chat Widget]: won't load without practiceId or isInitializing`)
			return
		}

		if (isChatInitialized.current && lastPracticeId.current !== practiceId) {
			console.log(`[Maven Chat Widget]: practice changed, closing existing chat widget`)
			closeChatWidget()
		}

		initChatWidget()
	}, [currentPracticeObject])

	useEffect(() => {
		return () => closeChatWidget()
	}, [])

	return null
}
