export enum THEMES {
	LIGHT = 'light',
	DARK = 'dark',
	NATURE = 'nature'
}

export const HEADER_HEIGHT = '64px'
export const FOOTER_HEIGHT = '50px'
export const DRAWER_WIDTH = '280px'
export const CONSOLIDATOR_ID_VETCORE = '8'
export const CONSOLIDATOR_ID_MVP = '3'
export const CONSOLIDATOR_ID_THRIVE = '11'
export const CONSOLIDATOR_ID_RAREBREED = '65'
export const CONSOLIDATOR_ID_BRM = '5'
export const PARTNER_ID_BRAVA = 'brava'

export const DISABLE_PAYOUT_REPORTING_SUMMARY_IDS: Array<string | undefined> = [
	CONSOLIDATOR_ID_BRM,
	CONSOLIDATOR_ID_THRIVE,
	CONSOLIDATOR_ID_VETCORE
]

export const PAYMENT_STATUS = {
	SUCCEEDED: 'succeeded',
	CANCELED: 'canceled',
	REQUIRES_PAYMENT_METHOD: 'requires_payment_method'
}

export const PRACTICE_ROLE_SCRATCH_ADMIN = 'scratchAdmin'
export const PRACTICE_ROLE_ADMIN = 'admin'
export const PRACTICE_ROLE_ACCOUNTING_MANAGER = 'accountingManager'
export const PRACTICE_ROLE_HOSPITAL_MANAGER = 'hospitalManager'
export const PRACTICE_ROLE_REGIONAL_MANAGER = 'regionalManager'
export const PRACTICE_ROLE_STAFF = 'staff'
export const FINANCING_ONLY_PRACTICE_ROLE = PRACTICE_ROLE_HOSPITAL_MANAGER

export const ENV_PRODUCTION = 'production'

export const US_STATES = [
	'AL',
	'AK',
	'AZ',
	'AR',
	'CA',
	'CO',
	'CT',
	'DC',
	'DE',
	'FL',
	'GA',
	'HI',
	'ID',
	'IL',
	'IN',
	'IA',
	'KS',
	'KY',
	'LA',
	'ME',
	'MD',
	'MA',
	'MI',
	'MN',
	'MS',
	'MO',
	'MT',
	'NE',
	'NV',
	'NH',
	'NJ',
	'NM',
	'NY',
	'NC',
	'ND',
	'OH',
	'OK',
	'OR',
	'PA',
	'RI',
	'SC',
	'SD',
	'TN',
	'TX',
	'UT',
	'VT',
	'VA',
	'WA',
	'WV',
	'WI',
	'WY'
]

export const CA_STATES = ['AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'ON', 'PE', 'QC', 'SK']

export const CHARGE_STATUS_TEXT_UNCAPTURED = 'Uncaptured'
export const CHARGE_STATUS_TEXT_FAILED = 'Failed'
export const CHARGE_STATUS_TEXT_FAILED_E_CHECK = 'Failed eCheck'
export const CHARGE_STATUS_TEXT_RETURNED_E_CHECK = 'Reversed eCheck'
export const CHARGE_STATUS_DISPUTED = 'Disputed'
export const STATUS_SUCCESS = 'success'
export const STATUS_LOADING = 'loading'
export const RETURNED_E_CHECK = 'returnedECheck'

export const STATE_AMEX_BLOCKED = 'amex-blocked'
export const STATE_SUCCEEDED = 'succeeded'
export const STATE_FAILED = 'failed'
export const STATE_CANCELED = 'canceled'

export const AVIMARK = 'AVImark'
export const CORNERSTONE = 'Cornerstone'
export const DENTICON = 'Denticon'
export const DENTRIX = 'Dentrix'
export const EZYVET = 'ezyVet'
export const VETSPIRE = 'vetspire'

export const PIMS_OPTIONS = [AVIMARK, CORNERSTONE, DENTICON, DENTRIX, VETSPIRE, EZYVET, 'Other']
export const PIMS_OPTIONS_FOR_VETCOR = [
	AVIMARK,
	CORNERSTONE,
	'DVMax',
	'EVetPractice',
	EZYVET,
	'IDEXX Neo',
	'Infinity',
	'Intravet',
	VETSPIRE,
	'Vetter',
	'VIA',
	'VTech',
	'Other'
]

export const COUNTRY_US = 'US'
export const COUNTRY_CA = 'CA'

export const CURRENCY_US = 'usd'
export const CURRENCY_CA = 'cad'

export const CONNECTION_STATUS_CONNECTED = 'connected'
export const CONNECTION_STATUS_CONNECTING = 'connecting'

export const REMINDER_TYPES = {
	REMINDER: 'reminder',
	CONFIRMATION: 'confirmation'
}

export const COMMUNICATION_REPORTING_TYPES = {
	METRICS: 'metrics',
	HISTORY: 'history'
}

export const READER_ADMIN_PIN = '07139'

export const checkScratchPaymentMethods = [
	'lockbox_check',
	'check_payment',
	'Check Payment',
	'us_bank_account',
	'paper_check',
	'ach_debit'
]

export const bankPaymentMethods = ['us_bank_account', 'acss_debit']

export const AG_GRID_EXPORT_TYPES = {
	CSV: 'csv',
	EXCEL: 'excel',
	PRINTER_FRIENDLY_CSV: 'printer_friendly_csv'
}

export const ECHECK_PAYMENT_METHOD = 'check_payment'

export const ECHECK_PAYMENT_PAGES = {
	MAIN: 'main',
	CHECK: 'check',
	LOCKBOX: 'lockbox'
}

export const enum ACTION_NEEDED_RESOLUTION {
	LOCKBOX_MAILED = 'lockbox_mailed',
	PAYMENT_ENTERED = 'payment_entered',
	RERAN_CHECK = 'reran_check',
	PAID_BY_OTHER_MEANS = 'paid_by_other_means',
	NOT_COLLECTTED = 'not_collected',
	GET_LOCKBOX_ID = 'get_lockbox_id',
	NO_RESOLUTION = 'no_resolution',
	ADDED_TO_PIMS = 'added_to_pims'
}

export const enum ACTION_ITEM_TYPES {
	FAILED_CHECK = 'FAILED_CHECK',
	AR_PAYMENT_RECEIVED = 'AR_PAYMENT_RECEIVED',
	FAILED_LOCKBOX = 'FAILED_LOCKBOX',
	MOBILE_PAYMENT = 'MOBILE_PAYMENT',
	SUCCEEDED_OFFLINE = 'OFFLINE_PAYMENT_SUCCEEDED',
	FAILED_OFFLINE = 'OFFLINE_PAYMENT_FAILED'
}

export const COMMS_SIDEBAR_WIDTH = 400

export const enum CalendlyEvent {
	PROFILE_PAGE_VIEWED = 'calendly.profile_page_viewed',
	EVENT_TYPE_VIEWED = 'calendly.event_type_viewed',
	DATE_AND_TIME_SELECTED = 'calendly.date_and_time_selected',
	EVENT_SCHEDULED = 'calendly.event_scheduled',
	PAGE_HEIGHT = 'calendly.page_height'
}

export const enum RequestLevel {
	PRACTICE = 'PRACTICE',
	MANAGER = 'MANAGER',
	ADMIN = 'ADMIN',
	SCRATCHADMIN = 'SCRATCHADMIN'
}

export const enum TwilioFlowName {
	TEXT_APPLICATION = 'textApplication'
}

export const SHIPPING_METHODS = [
	{ type: 'standard', label: 'Standard (5-7 Business Days)' },
	{ type: 'express', label: 'Express (3-4 Business Days)' },
	{ type: 'priority', label: 'Priority (1-2 Business Days)' }
]

export const ROUTES = {
	accountUri: 'financing/account',
	bankAccountUri: 'financing/account/bank-account',
	plansUri: 'financing/plans'
}

export const MANUAL_APPOINTMENT_INSERT_FLAG = 'isManualAppointmentInsertEnabled'
export const SMART_APPOINTMENT_REQUESTS_FLAG = 'isSmartAppointmentRequestsEnabled'
export const DOCTOR_CUSTOMIZATIONS_FLAG = 'isDoctorCustomizationsEnabled'
export const DIRECT_BOOKING_FLAG = 'isDirectBookingEnabled'
export const DIRECT_BOOKING_STORED_CARD_FLAG = 'isDirectBookingStoredCardEnabled'
export const DIRECT_BOOKING_DEPOSIT_FLAG = 'isDirectBookingDepositEnabled'
export const REPUTATION_EMAIL_FLAG = 'isReputationEmailEnabled'
export const COMMS_SETTINGS_FLAG = 'isCommunicationSettingsEnabled'
export const REMINDERS_ENABLED_FLAG = 'isRemindersEnabled'
export const APPOINTMENT_REQUESTS_ENABLED_FLAG = 'isAppointmentRequestsEnabled'
export const POSTCARD_PAGE_ENABLED_FLAG = 'isPostcardsPageEnabled'

export const SCRATCH_PAY_PUBLIC_URL_STG = 'https://client.staging.scratchpay.com'
export const SCRATCH_PAY_PUBLIC_URL_PRD = 'https://client.scratchpay.com'

export const FINANCING_STATUS = {
	ACTIVE: 'active',
	HIDDEN: 'hidden'
}

export const PLANS_FILTER_ON_OPTIONS = {
	APPLIED_DATE: 'Applied',
	CONFIRMED_DATE: 'Confirmed'
}

export const CUSTOM_PAYOUT_TARGET_FILTER_ON_OPTIONS = ['PRACTICE', 'CONSOLIDATOR']
export const DEFAULT_GQL_PAGE_SELECTOR = [15, 50, 100, 250]

export const PLANS_QUICK_FILTERS = {
	ALL: 'All',
	LAST_7_DAYS: 'Last 7 days',
	NEW: 'New',
	FINALIZED: 'Finalized',
	REOPEN: 'Reopen',
	APPROVED: 'Approved',
	REJECTED: 'Rejected',
	EXPIRED: 'Expired'
}

export const CARD_BRANDS = {
	AMEX: 'amex'
}

export const DENIED_AMEX_CARD_MESSAGE =
	'Your practice is not accepting Amex at this time. Please try a different card.'

export const INDUSTRY_VETERINARY = 'veterinary'

export const INDUSTRY_OPTIONS = [
	{
		id: 1,
		content: 'Veterinary',
		value: INDUSTRY_VETERINARY
	},
	{
		id: 2,
		content: 'Dental',
		value: 'dental'
	},
	{
		id: 3,
		content: 'Lasik and Vision',
		value: 'lasik_and_vision'
	},
	{
		id: 4,
		content: 'Weight Loss',
		value: 'weight_loss'
	},
	{
		id: 5,
		content: 'MedSpa',
		value: 'medSpa'
	},
	{
		id: 6,
		content: 'Audiology',
		value: 'audiology'
	},
	{
		id: 7,
		content: 'Cosmetic',
		value: 'cosmetic'
	},
	{
		id: 8,
		content: 'Dermatology',
		value: 'dermatology'
	},
	{
		id: 9,
		content: 'Chiropractic',
		value: 'chiropractic'
	},
	{
		id: 10,
		content: 'Fertility',
		value: 'fertility'
	},
	{
		id: 11,
		content: 'Physical Therapy',
		value: 'physical_therapy'
	},
	{
		id: 12,
		content: 'Primary Care',
		value: 'primary_care'
	},
	{
		id: 13,
		content: 'Specialists',
		value: 'specialists'
	}
]

export const COUNTRY_OPTIONS = [
	{
		content: 'United States',
		value: 'US'
	},
	{
		content: 'Canada',
		value: 'CA'
	}
]

export enum PERSONA_PROCESS_TYPES {
	SIGN_UP = 'SIGN_UP',
	'1099_INFO' = '1099_INFO'
}

export const PERSONA_PROCESS_STATUS = {
	PENDING: 'pending',
	COMPLETED: 'completed',
	APPROVED: 'approved',
	FAILED: 'failed',
	DECLINED: 'declined'
}

export enum PracticeCreationError {
	PaymentPracticeAlreadyExists = 'PAYMENT_PRACTICE_ALREADY_EXISTS',
	FinancingPracticeAlreadyExists = 'FINANCING_PRACTICE_ALREADY_EXISTS',
	AlreadyExists = 'PRACTICE_ALREADY_EXISTS',
	GenericCreationError = 'PRACTICE_GENERIC_CREATION_ERROR'
}

export const CREATE_PRACTICE_ERRORS = {
	E3011: {
		practiceError: PracticeCreationError.AlreadyExists,
		toastMessage: 'This practice already has a Scratch account.'
	},
	E3012: {
		practiceError: PracticeCreationError.PaymentPracticeAlreadyExists,
		toastMessage: 'This practice already has a Scratch Payment account.'
	},
	E3013: {
		practiceError: PracticeCreationError.FinancingPracticeAlreadyExists,
		toastMessage: 'This practice already has a Scratch Financing account.'
	}
}

export const CONSOLIDATOR_OPTIONS = [
	{ id: 2, value: 'Lakefield' },
	{ id: 3, value: 'Mission Veterinary Partners' },
	{ id: 4, value: 'Banfield' },
	{ id: 5, value: 'Blue River' },
	{ id: 6, value: 'Southern Veterinary Partners' },
	{ id: 7, value: 'VEG' },
	{ id: 8, value: 'VetCor' },
	{ id: 9, value: 'OSVS' },
	{ id: 10, value: 'MAVANA' },
	{ id: 11, value: 'Pathway' },
	{ id: 12, value: 'Vets Pets' },
	{ id: 13, value: 'National Veterinary Associates' },
	{ id: 14, value: 'Heartland Veterinary Partners' },
	{ id: 15, value: 'PetVet Care Centers' },
	{ id: 16, value: 'VitalPet' },
	{ id: 17, value: 'Compassion-First Pet Hospitals' },
	{ id: 18, value: 'Corner Vet' },
	{ id: 19, value: 'American Veterinary Group' },
	{ id: 20, value: 'BluePearl' },
	{ id: 21, value: 'Cityvet' },
	{ id: 22, value: 'Community Vet Partners' },
	{ id: 23, value: 'CAPNA' },
	{ id: 24, value: 'Ethos' },
	{ id: 25, value: 'Heart & Paw' },
	{ id: 26, value: 'Innovetive Petcare' },
	{ id: 27, value: 'LegacyVet' },
	{ id: 28, value: 'Medvet Medical & Cancer Centers' },
	{ id: 29, value: 'O’Brien Veterinary Management' },
	{ id: 30, value: 'Petwell Partners' },
	{ id: 31, value: 'VCA' },
	{ id: 32, value: 'Veterinary Practice Partners' },
	{ id: 33, value: 'Wellhaven' },
	{ id: 34, value: 'SAGE Centers' },
	{ id: 36, value: 'CareVet' },
	{ id: 37, value: 'Small Door Veterinary' },
	{ id: 38, value: 'Modern Animal' },
	{ id: 39, value: 'Encore Vet Group' },
	{ id: 40, value: 'Kremer Veterinary Services' },
	{ id: 41, value: 'Petco Hospitals' },
	{ id: 42, value: 'People Pets & Vets' },
	{ id: 43, value: 'Exceptional Pets' },
	{ id: 44, value: 'VerticalVet' },
	{ id: 45, value: 'Easyvet' },
	{ id: 46, value: 'Pets Wellness Alliance' },
	{ id: 47, value: 'University of Missouri' },
	{ id: 48, value: 'Urgent Care 24/7' },
	{ id: 49, value: 'Pieper Veterinary' },
	{ id: 50, value: 'Western Dental' },
	{ id: 51, value: 'Suveto' },
	{ id: 52, value: 'Sabuska' },
	{ id: 53, value: 'CVCA' },
	{ id: 54, value: "Vet's Best Friend" },
	{ id: 55, value: 'NVISION' },
	{ id: 56, value: 'Smiles West' },
	{ id: 57, value: 'Dental Wonderland' },
	{ id: 58, value: 'Ponderosa Dental Group' },
	{ id: 59, value: 'Desert Dental Group' },
	{ id: 60, value: "Cohen's Fashion Optical" },
	{ id: 61, value: 'Lice Clinics of America' },
	{ id: 62, value: 'Access Dental' },
	{ id: 63, value: 'La Costa Dental' },
	{ id: 64, value: 'THRIVE' },
	{ id: 65, value: 'Rarebreed' },
	{ id: 66, value: 'Family Vet Group' },
	{ id: 67, value: 'FIT Medical Weight Loss' },
	{ id: 68, value: 'Five Point Dental Specialists' },
	{ id: 69, value: 'Affderm' },
	{ id: 70, value: 'VPC Clinics' },
	{ id: 71, value: 'South Valley Oral & Facial Surgery' },
	{ id: 72, value: 'Cal Dental' },
	{ id: 73, value: 'VetNCare' },
	{ id: 74, value: 'United Veterinary Care' },
	{ id: 75, value: 'Western Veterinary Partners' },
	{ id: 76, value: 'Arizona Pain Relief' },
	{ id: 77, value: 'My Pets Wellness' },
	{ id: 78, value: 'NJ Smiles Dental' },
	{ id: 79, value: 'Care Dentistry' },
	{ id: 80, value: 'Hudec Dental' },
	{ id: 81, value: 'Pacific Dental Services' },
	{ id: 82, value: 'Veterinary Innovative Partners' },
	{ id: 83, value: 'United Animal Care' },
	{ id: 84, value: 'Bond Vet' },
	{ id: 85, value: 'Restore Hyper Wellness' },
	{ id: 86, value: 'Sploot Veterinary Care' },
	{ id: 87, value: 'Sage Dental' },
	{ id: 88, value: 'VetStrategy' },
	{ id: 89, value: 'Valley Veterinary Care' },
	{ id: 90, value: 'Smile Brands Group' },
	{ id: 92, value: 'Curo PetCare' },
	{ id: 93, value: 'Associated Veterinary Partners' },
	{ id: 94, value: 'AmeriVet' },
	{ id: 95, value: 'VetmEDUcate' },
	{ id: 96, value: 'Petfolk' },
	{ id: 97, value: 'Veterinarian Partners' },
	{ id: 98, value: 'Alliance Animal Health' },
	{ id: 99, value: 'Jacksonville Community Pet' },
	{ id: 100, value: 'Professional PT' },
	{ id: 101, value: 'WellFit - Independent' },
	{ id: 102, value: 'Ocean Avenue Inc' }
]

export const PIMS_SIGN_UP_OPTIONS = [
	{ id: 1, value: 'Avimark' },
	{ id: 2, value: 'Cornerstone' },
	{ id: 3, value: 'Pulse (eVetPractice)' },
	{ id: 4, value: 'ezyVet' },
	{ id: 5, value: 'Neo' },
	{ id: 6, value: 'Impromed Infinity' },
	{ id: 7, value: 'Instinct' },
	{ id: 8, value: 'Rhapsody' },
	{ id: 9, value: 'Triple Crown' },
	{ id: 10, value: 'Vetspire' },
	{ id: 11, value: 'Vetter' }
]

export const OTHER = 'Other'

export const US_STATE_ABBREVIATIONS = {
	COLORADO: 'CO',
	CONNECTICUT: 'CT',
	MAINE: 'ME',
	MASSACHUSETTS: 'MA',
	NEW_YORK: 'NY'
}
