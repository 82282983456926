import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import { useCurrentPractice } from 'contexts/PracticeContext'
import FrontAppChatWidget from 'hooks/frontAppChatWidget'
import { selectUser } from 'selectors/auth'
import { shouldShowMavenChat as consolidatorShouldShowMavenChat } from 'util/consolidator'
import { shouldShowMavenChat as practiceShouldShowMavenChat } from 'util/practice'

import { MavenChatWidget } from './MavenChatWidget'

export const ChatWidget = () => {
	const currentUser = useSelector(selectUser)
	const { currentPracticeObject, currentUserConsolidator } = useCurrentPractice()

	const useMavenChat = useMemo(() => {
		if (
			!currentUser ||
			isEmpty(currentPracticeObject) ||
			(currentUser?.consolidatorId && isEmpty(currentUserConsolidator)) // user consolidator context times sometime to load
		)
			return undefined
		if (practiceShouldShowMavenChat(currentPracticeObject)) return true
		if (consolidatorShouldShowMavenChat(currentUserConsolidator)) return true
		return false
	}, [currentUser, currentPracticeObject, currentUserConsolidator])

	if (useMavenChat === undefined) {
		console.log('[Chat widget]: not ready yet')
		return null
	}

	if (useMavenChat) {
		console.log('[Chat widget]: will show Maven Chat Widget')
		return <MavenChatWidget />
	}

	console.log('[Chat widget]: will show FrontApp Chat Widget')
	return <FrontAppChatWidget />
}
