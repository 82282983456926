import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useCurrentPractice } from '../contexts/PracticeContext'
import { selectUser } from '../selectors/auth'
import { getUserHash } from '../apis/frontApp'
import { FRONT_APP_CHAT_ID } from '../config'

declare global {
	interface Window {
		FrontChat: any
	}
}

const FrontAppChatWidget = () => {
	const user = useSelector(selectUser)
	const { currentPracticeObject } = useCurrentPractice()
	const isChatInitialized = useRef(false)
	const isInitializing = useRef(false)
	const lastPracticeId = useRef<string | null>(null)

	const getPracticeId = () => currentPracticeObject?.id ?? null

	const closeFrontAppChat = () => {
		if (window.FrontChat) {
			window.FrontChat('shutdown', { clearSession: true })
			isChatInitialized.current = false
			isInitializing.current = false
		}
	}

	const initChatWidget = async () => {
		if (isInitializing.current || isChatInitialized.current || !user || !currentPracticeObject) {
			console.log(`[FrontApp Chat Widget]: won't load due to missing data`)
			return
		}

		try {
			console.log('[FrontApp Chat Widget]: initializing')
			isInitializing.current = true
			const response = await getUserHash()
			const { userHash } = response.data

			if (userHash) {
				window.FrontChat('init', {
					useDefaultLauncher: true,
					email: user?.email,
					chatId: FRONT_APP_CHAT_ID,
					userHash,
					name: user?.name,
					customFields: {
						'Stripe Account ID': currentPracticeObject?.primaryClinic,
						'Clinic Name': currentPracticeObject?.primaryClinicName,
						'Group ID': currentPracticeObject?.consolidatorId
					},
					onInitCompleted: () => {
						console.log('[FrontApp Chat Widget]: initialized')
						isChatInitialized.current = true
						isInitializing.current = false
						lastPracticeId.current = getPracticeId()
					}
				})
			}
		} catch (err) {
			console.error('[FrontApp Chat Widget]: Error initializing FrontChat:', err)
		} finally {
			isInitializing.current = false
		}
	}

	useEffect(() => {
		const practiceId = getPracticeId()

		if (!practiceId || !user) {
			console.log('[FrontApp Chat Widget]: empty data, closing chat widget')
			closeFrontAppChat()
			return
		}

		if (isChatInitialized.current && lastPracticeId.current !== practiceId) {
			console.log('[FrontApp Chat Widget]: practice changed, closing chat widget')
			closeFrontAppChat()
		}

		initChatWidget()
	}, [user, currentPracticeObject])

	useEffect(() => {
		return () => {
			setTimeout(() => {
				console.log('[FrontApp Chat Widget]: unmounting')
				closeFrontAppChat()
			}, 500)
		}
	}, [])
	return null
}

export default FrontAppChatWidget
