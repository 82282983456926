import { User as FirebaseUser } from 'firebase/auth'
import { createClient } from 'urql'
import { Dispatch, SetStateAction } from 'react'
import {
	getUser,
	memoizeApiCall,
	useClinicSelect as useFirestoreClinicSelect,
	usePractices as useFirestorePractices,
	getConsolidator as getFirestoreConsolidator
} from './db'
import { getContext, updateUser } from '../apis/user'
import { UNIFIED_CONFIG } from '../config'
import { PRACTICE_LIST_QUERY } from '../gql/practice'
import { RequestLevel } from '../constants'
import { gqlClientOptions } from '../gql'
import { getUserPrimaryClinic } from './user'

/**
 * Whether or not the current application should have Unified Login enabled, based on the hostname.
 */
export function isUnifiedDomain(): boolean {
	// unified dashboard can be dashboard.scratchpay.com or dashboard.staging.scratchpay.com or 127.0.0.1
	return (
		window.location.hostname.search(/dashboard\.(staging\.)?scratchpay\.com/i) > -1 ||
		window.location.hostname.search('127.0.0.1') > -1 ||
		window.location.hostname.search('localhost') > -1
	)
}

/**
 * Return `true` if we should use the unified context for login.
 * @param u the firebase user
 */
export function isUnifiedEnabled(email?: string | null | undefined) {
	if (UNIFIED_CONFIG.enabled || isUnifiedDomain()) return true
	if (!email) return false
	if (UNIFIED_CONFIG.emailDomains.find((d) => email?.endsWith(d))) {
		return true
	}
	return false
}

// TODO: remove this and use stripeAccountId correctly
export function convertGqlToIPractice(
	prac,
	consolidatedPractice?: IConsolidatedPractice
): IPractice | null {
	if (!prac) {
		return null
	}
	return {
		...prac,
		checkOnly: prac.settings?.checkOnly,
		isCheckEnabled: prac.settings?.isCheckEnabled,
		flags: {
			paymentRequestReminders: prac.settings?.paymentRequestReminders
		},
		settings: {
			...prac.settings,
			...prac.commsSettings
		},
		officeHours: prac.commsSettings?.officeHours,
		serverDrivenTerminal: prac.settings?.serverDrivenTerminal,
		additionalStripeRequirements: prac.settings?.additionalStripeRequirements,
		additionalStripeRequiredInfo: prac.settings?.additionalStripeRequiredInfo,
		additionalStripeFutureRequirements: prac?.stripeFutureRequirements,
		customPayoutTimings: prac.settings?.customPayoutTimings,
		clinicName: prac.name,
		clinicState: prac.state,
		clinicCity: prac.city,
		clinicCountry: prac.country,
		clinicTimezone: prac.timezone,
		clinicIndustry: prac.industry,
		isChatEnabled: prac.isChatEnabled || prac?.settings?.isChatEnabled,
		dbid: prac.id,
		cuid: prac.stripeAccountId ?? prac.id,
		id: prac.stripeAccountId ?? prac.id, // TODO remove this as we move away from acct_...
		stripeAccountStatus: {
			chargesEnabled: prac.products?.payments?.chargesEnabled,
			payoutsEnabled: prac.products?.payments?.payoutsEnabled
		},
		consolidatedPractice
	} as IPractice
}

// TODO: remove this and use featureFlags, not settings.
export function convertGqlToIConsolidator(cons): IConsolidator | null {
	if (!cons) {
		return null
	}
	return {
		...cons,
		hideLockBox: cons.featureFlags?.hideLockBox,
		settings: cons.featureFlags
	} as IConsolidator
}

/**
 * Validate that the user is correct against the DB annd allow them to login
 * @param u the firebase user to validate
 * @returns a Context that includes the User, Practice, and Consolidator retrieved from the DB,
 *    but if there's an error fall back to firestore
 */
export async function validateUser(u: FirebaseUser): Promise<Context | null> {
	try {
		if (isUnifiedEnabled(u?.email)) {
			const context = await getContext()
			if (context) {
				if (!context.success) {
					return validateFirestoreUser(u)
				}

				// Select first practice from user.practices when user.primaryClinicId is empty
				const firstClinic = context.data.user?.practices[0]?.practice
				const primaryClinicId = context.data.user?.primaryClinicId ?? firstClinic?.id

				// TODO SLT Reenable for lending-only users
				if (context.data?.user?.uid) {
					const lastLoginDate = new Date()
					const updateUserPayload: any = { lastLoginDate }
					if (!context.data.user?.primaryClinicId && firstClinic?.stripeAccountId) {
						updateUserPayload.primaryClinic = firstClinic.stripeAccountId
					}
					await updateUser(u.uid, updateUserPayload).catch((err) => console.error(err))
				}

				// Hack some context to keep things kosher.
				context.data.user = {
					uid: u.uid,
					email: u.email!,
					emailVerified: u.emailVerified,
					displayName: u.displayName ?? undefined,
					// TODO SLT: These parameters should go away and be replaced.
					primaryClinic: primaryClinicId?.replace('prac_', 'acct_'),
					...context.data.user
				}

				return context.data
			}
		}
	} catch (err) {
		console.error(err)
	}
	return validateFirestoreUser(u)
}

/**
 * Validate that the user is correct, and handle if they are
 * @param u the firebase user to validate
 * @returns a User retrieved from Firestore
 */
export async function validateFirestoreUser(u: FirebaseUser): Promise<Context | null> {
	try {
		const firestoreUserRef = await getUser(u.uid)
		if (!firestoreUserRef.exists()) {
			return null
		}
		const firestoreUser = firestoreUserRef.data()
		const lastLoginDate = new Date()

		// update user.primaryClinic when it is empty
		const updateUserPayload: any = { lastLoginDate }
		let primaryClinic = firestoreUser?.primaryClinic
		if (!primaryClinic) {
			primaryClinic = getUserPrimaryClinic(firestoreUser as User)
			updateUserPayload.primaryClinic = primaryClinic
		}
		await updateUser(u.uid, updateUserPayload).catch((err) => console.error(err)) // Don't fail if we can't update

		return {
			user: {
				uid: u.uid,
				email: u.email!,
				emailVerified: u.emailVerified,
				displayName: u.displayName ?? undefined,
				lastLoginDate,
				...firestoreUser,
				primaryClinic
			}
		}
	} catch (err) {
		console.error('Something went wrong on changing auth state')
	}
	return null
}

export function useClinicSelect(context: Context) {
	const { user } = context
	if (isUnifiedEnabled(user?.email)) {
		return memoizeApiCall(async () => {
			if (user.isAdmin && !user.isScratchAdmin) {
				// Use practice level because RequestLevel for Practice works for all
				// admin types.
				const graphQLClientOptions = {
					requestLevel: RequestLevel.PRACTICE
				}
				const graphQLClient = createClient(gqlClientOptions(graphQLClientOptions))
				const practiceQuery = graphQLClient
					.query(PRACTICE_LIST_QUERY, {
						take: null,
						orderBy: {
							name: 'asc'
						}
					})
					.toPromise()
				return practiceQuery.then((result) =>
					result.data?.practices.map((prac) => convertGqlToIPractice(prac))
				)
			}
			return Promise.resolve(
				context.user.practices.map((prac) => convertGqlToIPractice(prac.practice))
			)
		})
	}
	return useFirestoreClinicSelect(
		user?.isAdmin,
		user?.isRegionalManager,
		user?.practices,
		user.uid,
		user?.consolidatorId,
		user?.isScratchAdmin
	)
}

/**
 * Query the practices in the DB for a scratch admin to avoid querying 20K practices
 * @param inputField the filter criteria
 * @param setOptions the options to set once the query retursn
 * @returns
 */
export function queryPractices(
	inputField: string | undefined,
	options: IPractice[],
	setOptions: Dispatch<SetStateAction<IPractice[]>>
) {
	const graphQLClientOptions = {
		requestLevel: RequestLevel.SCRATCHADMIN
	}
	// Don't bother
	if (inputField === '' || inputField === undefined) {
		return undefined
	}
	const fetch = async () => {
		const graphQLClient = createClient(gqlClientOptions(graphQLClientOptions))
		graphQLClient
			.query(PRACTICE_LIST_QUERY, {
				take: null,
				orderBy: {
					name: 'asc'
				},
				// TODO: do something with the city/state maybe?
				where: {
					OR: [
						{
							name: {
								contains: inputField,
								mode: 'insensitive'
							}
						},
						{
							stripeAccountId: {
								contains: inputField
							}
						}
					]
				}
			})
			.then((prac) => {
				if (prac.error) {
					console.error(`Problem querying practices: ${prac.error}`)
					return
				}
				setOptions(
					prac.data?.practices?.map((p) => convertGqlToIPractice(p)).filter(Boolean) as IPractice[]
				)
			})
	}
	fetch().catch(console.error)
}

// This is a tricky function, because keeping all the react law-of-hooks working is difficult, since firestore
// Is done using
export function usePractices(context: Context, cuid: string | undefined) {
	// TODO: update the context as well?
	if (isUnifiedEnabled(context?.user?.email)) {
		return memoizeApiCall(() => {
			if (cuid === null || cuid === undefined) {
				return Promise.resolve(null)
			}
			if (
				context.practice &&
				(context.practice.id === cuid || context.practice.stripeAccountId === cuid)
			) {
				return Promise.resolve(
					convertGqlToIPractice(context.practice, context?.consolidatedPractice)
				)
			}
			return getContext(cuid).then((c: any) =>
				convertGqlToIPractice(c.data?.practice, c.data?.consolidatedPractice)
			)
		}, [cuid])
	}
	return useFirestorePractices(cuid)
}

// TODO: remove this function, as we should only get the consolidator from the context directly
export function getConsolidator(context: Context, consolidatorId: string | undefined) {
	if (isUnifiedEnabled(context?.user?.email)) {
		if (!consolidatorId) {
			return {
				exists: () => false
			}
		}
		if (context?.consolidator?.id === consolidatorId) {
			return {
				exists: () => true,
				data: () => convertGqlToIConsolidator(context.consolidator)
			}
		}
		if (context?.practice?.consolidator?.id === consolidatorId) {
			return {
				exists: () => true,
				data: () => convertGqlToIConsolidator(context.practice.consolidator)
			}
		}
		return {
			exists: () => false
		}
	}
	return getFirestoreConsolidator(consolidatorId)
}

/**
 * @param context the context for the call, non-optional
 * @param practice the optional practice to use if you have currentPracticeObject
 * @returns whether the user has a defined role for the practice
 */
export const shouldShowSelectRolePage = (context: Context, practice?: IPractice): boolean => {
	const user = context?.user

	if (!user || !isUnifiedEnabled(user?.email) || user?.isAdmin || user?.isScratchAdmin) {
		return false
	}

	const practiceToCheck = (practice?.id ?? practice?.stripeAccountId) ? practice : context.practice
	const selectedPracticeId =
		practiceToCheck?.dbid ?? practiceToCheck?.id ?? practiceToCheck?.stripeAccountId

	const userPractice = user.practices.find((p) => p?.practice?.id === selectedPracticeId)

	// If it's not a payment practice, don't show the select role banner
	if (!userPractice || !userPractice?.practice?.stripeAccountId) {
		return false
	}

	// Only show the page if user has all roles explicity set as false
	return (
		userPractice?.isRegionalManager === false &&
		userPractice?.isHospitalManager === false &&
		userPractice?.isStaff === false
	)
}

/**
 * @param context the context for the call, non-optional
 * @param practice the optional practice to use if you have currentPracticeObject
 * @returns whether the user is a hospital manager, regional manager, or admin for the context (or override) practice
 */
export function isManagerOrAdmin(context: Context, practice?: IPractice): boolean {
	if (!context.user) {
		return false
	}

	if (context.user.isScratchAdmin || context.user.isAdmin || context.user.isRegionalManager) {
		return true
	}

	// Check firestore style
	const practiceToCheck = practice ?? context.practice
	if (practiceToCheck) {
		// Check firestore hmUsers/regionalManagerId by hand
		try {
			if (
				practiceToCheck?.hmUsers?.includes(context.user.uid) ||
				practiceToCheck?.regionalManagerId?.includes(context.user.uid)
			) {
				return true
			}
		} catch (err: any) {
			console.error(err)
		}
	}

	// Check DB style
	if (context.user?.practices?.length > 0) {
		const selectedPracticeId =
			practiceToCheck?.dbid ?? practiceToCheck?.id ?? practiceToCheck?.stripeAccountId
		const poc = context.user.practices.find((p) => p?.practice?.id === selectedPracticeId)
		if (poc && poc.isHospitalManager) {
			return true
		}
	}

	return false
}

/**
 * @param context the context for the call, non-optional
 * @param practice the optional practice to use if you have currentPracticeObject
 * @returns whether the user is a hospital manager for the context (or override) practice
 */
export function isHospitalManager(context: Context, practice?: IPractice): boolean {
	if (!context.user) {
		return false
	}

	// Check firestore style
	const practiceToCheck = practice ?? context.practice
	if (practiceToCheck) {
		// Check firestore hmUsers by hand
		try {
			if (practiceToCheck?.hmUsers?.includes(context.user.uid)) {
				return true
			}
		} catch (err: any) {
			console.error(err)
		}
	}

	// Check postgres style (unified_enabled = true)
	if (context.user?.practices?.length > 0) {
		const selectedPracticeId =
			practiceToCheck?.dbid ?? practiceToCheck?.id ?? practiceToCheck?.stripeAccountId
		const poc = context.user.practices.find((p) => p?.practice?.id === selectedPracticeId)
		return poc && !!poc.isHospitalManager
	}

	return false
}

export function getTransactionFees(practice: IPractice): IPracticeTransactionFees | undefined {
	if (practice?.transactionFeeAmt) {
		return {
			amount: practice.transactionFeeAmt,
			percentage: practice.transactionFeePct,
			areNet: !!(practice?.transactionFeesAreNet || 0),
			feeTier: practice?.transactionFeeTier,
			storedCardPct: practice?.transactionFees?.storedCardPct
		}
	}
	return practice?.transactionFees
}
