import { useMemo } from 'react'
import { DateTime } from 'luxon'
import { useCurrentPractice } from 'contexts/PracticeContext'
import { Banner, BannerVariant } from 'components/common/banner'
import { isEmpty } from 'lodash'
import { Box, Button, Typography } from '@mui/material'
import { isFinancingOnlyPractice, isIndependentPractice } from '../../../../util/practice'
import Card from '../../../../icons/Card'

export const IndependentPracticeWVCBanner = () => {
	const { currentPracticeObject } = useCurrentPractice()

	const isSMBPractice = useMemo(
		() => !isEmpty(currentPracticeObject) && isIndependentPractice(currentPracticeObject),
		[currentPracticeObject]
	)

	const isLendingOnlyPractice = useMemo(
		() => isFinancingOnlyPractice(currentPracticeObject),
		[currentPracticeObject]
	)

	const isBetweenDates = () => {
		const now = DateTime.now()
		const startOfFeb25 = DateTime.local(2025, 2, 25).startOf('day')
		const endOfMar5 = DateTime.local(2025, 3, 5).endOf('day')

		return now >= startOfFeb25 && now <= endOfMar5
	}

	const showWVCBanner = isSMBPractice && isLendingOnlyPractice && isBetweenDates()

	if (isEmpty(currentPracticeObject) || !showWVCBanner) {
		return null
	}

	return (
		<Banner
			customIcon={
				<Box
					sx={(theme) => ({
						width: '90px',
						height: '85px',
						backgroundColor: theme.palette.mode === 'dark' ? '#5b70ff' : 'white',
						overflow: 'hidden',
						display: 'flex',
						alignItems: 'center',
						position: 'relative'
					})}
				>
					<Box
						sx={{
							width: '110px',
							height: '140px',
							backgroundColor: '#0a1689',
							borderBottom: 0,
							position: 'absolute',
							borderRadius: '50%',
							left: '-21px',
							top: '-26px'
						}}
					>
						<Card
							sx={{
								fontSize: '50px',
								display: 'flex',
								position: 'relative',
								top: '40px',
								left: '38px',
								width: '60px',
								height: '60px'
							}}
						/>
					</Box>
				</Box>
			}
			customActionButtons={
				<Button
					href={
						'https://get.scratchpay.com/demo/checkout?utm_source=Dashboard&utm_medium=website+&utm_campaign=WVC-2025'
					}
					target="_blank"
					component="a"
					sx={(theme) => ({
						marginRight: 4,
						height: '50px',
						width: '160px',
						fontWeight: 'bold',
						fontSize: '16px',
						backgroundColor: theme.palette.mode === 'dark' ? '#e8e8ff' : '#5B70FF',
						color: theme.palette.mode === 'dark' ? '#6376f5' : 'white',
						'&:hover': {
							backgroundColor: theme.palette.mode === 'dark' ? '#fcfcff' : '#314bff'
						}
					})}
				>
					Book a Demo
				</Button>
			}
			showButton={false}
			title={
				<Typography sx={{ fontFamily: 'Gabarito', fontWeight: 700, fontSize: '15px' }}>
					Visit Us at WVC - Booth #3832!
				</Typography>
			}
			subtitle={
				<Typography sx={{ fontWeight: 500, fontSize: '14px' }}>
					Discover{' '}
					<Typography
						sx={{
							display: 'inline',
							fontFamily: 'Gabarito',
							fontWeight: 600,
							fontSize: '15px',
							color: '#121f94',
							lineHeight: '1'
						}}
					>
						Scratch Checkout
					</Typography>
					, our payment processing solution designed exclusively for veterinarians. Stop by to learn
					more or book a demo to see how much you can save!
				</Typography>
			}
			variant={BannerVariant.WVC}
			showCloseButton={false}
		/>
	)
}
