export enum BannerVariant {
	INFO = 'info', // blue
	WARN = 'warn', // yellow
	ERROR = 'error', // red
	WVC = 'wvc'
}

export type BannerButtonProps = {
	href?: string
	disabled?: boolean
	buttonTitle?: string
	onButtonClick?: () => void
	variant: BannerVariant
	showButton?: boolean
}

export type BannerProps = {
	title: string | React.ReactNode
	subtitle: string | React.ReactNode
	tooltip?: string
	href?: string
	target?: string
	disabled?: boolean
	buttonTitle?: string
	onButtonClick?: () => void
	variant?: BannerVariant
	showButton?: boolean
	showCloseButton?: boolean
	customIcon?: React.ReactNode
	customActionButtons?: React.ReactNode
}
