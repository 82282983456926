import { createSvgIcon } from '@mui/material/utils'

const Card = createSvgIcon(
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 59 63" fill="none">
		<g>
			<path
				d="M35.0972 62.5221C34.8956 62.5221 34.6899 62.5006 34.4842 62.4549L2.19517 55.2627C1.46918 55.1014 0.835959 54.6579 0.436666 54.029C0.0373723 53.4015 -0.0957256 52.6408 0.0656052 51.9138L11.1504 2.19423C11.3117 1.46856 11.7554 0.835605 12.3846 0.436483C13.0124 0.0373614 13.7733 -0.0956792 14.5007 0.0655822L46.7897 7.2565C47.5157 7.41776 48.1489 7.86123 48.5482 8.49015C48.9475 9.11772 49.0806 9.87834 48.9192 10.6054L47.6299 16.3906C47.2925 17.9024 45.7935 18.8552 44.281 18.5179C42.7685 18.1806 41.8153 16.6822 42.1528 15.1704L42.8317 12.1226L16.0185 6.1532L6.15584 50.3966L32.9663 56.3673L36.2171 41.7853C36.5546 40.2734 38.0523 39.3193 39.5661 39.6579C41.0786 39.9953 42.0318 41.4936 41.6943 43.0055L37.8331 60.3263C37.5414 61.6325 36.3825 62.5221 35.0972 62.5221Z"
				fill="#5B70FF"
			/>
			<path
				d="M52.173 22.6895C52.1287 21.8375 52.1098 20.9814 52.1166 20.1268C52.1166 20.0206 52.1166 20.0757 52.1206 20.0676C52.1219 20.0676 52.1367 20.0811 52.1354 20.0703C52.1663 19.9628 52.1636 19.8647 52.0991 19.7451C52.0305 19.6322 51.9041 19.5422 51.7683 19.5382C51.7536 19.5382 51.7388 19.5382 51.724 19.5355L51.5223 19.5529C51.2413 19.5758 50.9603 19.608 50.6794 19.6363C46.7523 20.0717 42.8239 20.6737 38.921 21.2744C34.4481 21.9477 29.9618 22.4798 25.4499 22.7916L24.6715 22.8467C24.5935 22.848 24.5196 22.8588 24.4456 22.8696C23.9657 22.9542 23.5718 23.3695 23.4857 23.8627C23.4696 23.95 23.4642 24.0159 23.4602 24.1099L23.4669 24.2161L23.5301 25.06C23.8245 28.9935 24.2507 32.9417 24.7495 36.8523C24.7965 37.1721 24.8785 37.9005 24.9229 38.2176C25.091 38.9325 25.7134 39.2658 26.391 39.1207C32.6332 38.3816 38.8995 37.9072 45.1753 37.5417C46.2159 37.4825 52.4836 37.1318 53.4032 37.078C53.6868 37.1345 53.91 36.867 53.867 36.5795C53.7648 34.1915 53.5752 31.7887 53.2728 29.4127C52.9568 27.1699 52.4069 24.9444 52.173 22.6895ZM57.7672 22.2353C58.028 24.4728 57.9729 26.7358 58.1503 28.98C58.2364 30.1021 58.4031 31.2175 58.5429 32.3356C58.7217 33.584 58.8118 34.8728 58.9583 36.1387C59.1815 37.926 58.4931 39.7832 57.1595 40.9994C56.3017 41.8111 55.1509 42.3338 53.9705 42.4857C53.8414 42.5004 53.4529 42.5582 53.3158 42.5744C53.0334 42.6133 46.2307 43.5312 45.7547 43.5957C40.1525 44.3442 34.5423 44.996 28.9118 45.4582C27.6991 45.5389 26.4757 45.727 25.2631 45.5711C21.9181 45.1653 19.154 42.3056 18.8596 38.9755C18.5598 34.5247 18.2411 30.047 17.7128 25.615C17.6778 25.2441 17.5716 24.5225 17.5635 24.1502C17.3215 20.4708 20.2605 16.9728 23.9361 16.5562C24.1915 16.5253 24.5841 16.4702 24.8422 16.4419C31.5751 15.5805 38.3631 15.3924 45.1377 15.0941C47.2739 14.9825 49.5205 14.8199 51.6541 14.6372C53.9826 14.5323 56.2668 15.9501 57.1689 18.1863C57.6825 19.4737 57.6488 20.8793 57.7658 22.2339L57.7672 22.2353Z"
				fill="#5B70FF"
			/>
			<path
				d="M18.0354 25.9604L17.9615 25.955C17.9359 25.955 17.9575 25.9577 17.9534 25.9577C18.5073 26.1297 19.1311 26.123 19.7361 26.162C22.7127 26.2453 25.7403 25.9201 28.6913 25.4242C30.9607 25.0184 33.1293 24.1919 35.3919 23.7444C36.9299 23.4447 38.4895 23.2552 40.0477 23.055C44.8836 22.5228 49.8243 22.0431 54.6763 22.7459C57.8384 23.223 57.349 27.7746 54.1399 27.5475C54.1843 27.5582 54.1305 27.5609 54.1076 27.5663L54.0068 27.5811C48.2742 28.335 42.2727 29.6493 36.5535 30.5658C34.2398 30.8856 31.8332 30.8144 29.4966 31.0616C26.7473 31.3694 22.7396 31.958 19.9902 32.2617C19.0316 32.3504 18.0354 32.5063 17.0513 32.435C12.7881 31.6153 13.7077 25.4793 18.0368 25.9604H18.0354Z"
				fill="#5B70FF"
			/>
			<path
				d="M42.5819 31.7214C45.4254 31.0119 48.3804 30.8856 51.2978 31.1731L51.6944 31.2269C52.7807 31.3478 53.5618 32.3262 53.4408 33.412C53.3359 34.5193 52.2752 35.2786 51.2521 35.1644C51.1674 35.1724 50.9711 35.1859 50.8837 35.1912C48.2782 35.4036 45.6674 35.839 43.0377 35.7758C40.6338 35.6723 40.2829 32.3396 42.5819 31.7201V31.7214Z"
				fill="#5B70FF"
			/>
			<path
				d="M16.6144 46.1691C19.5425 46.2847 22.4142 46.9916 25.134 48.0855L25.4997 48.2481C26.508 48.6687 26.9839 49.8271 26.5631 50.835C26.1517 51.8684 24.9202 52.2997 23.9711 51.9047C23.8877 51.8885 23.6955 51.8469 23.6094 51.8281C21.0483 51.3013 18.4213 50.9882 15.9139 50.1913C13.6351 49.4185 14.2334 46.1208 16.613 46.1705L16.6144 46.1691Z"
				fill="#5B70FF"
			/>
		</g>
	</svg>,
	'Card'
)

export default Card
