import axiosClient from './base'

const REMOVE_READER_PATH = (practiceId, readerId) =>
	`/api/practices/${practiceId}/readers/${readerId}`

const UPDATE_READER_URL = (practiceId, readerId) =>
	`/api/practices/${practiceId}/readers/${readerId}`

const ORDER_READER_URL = (practiceId) => `/api/practices/${practiceId}/readers/order-reader`

const CREATE_READER_URL = (practiceId) => `/api/practices/${practiceId}/readers`

const UPDATE_READER_CONFIGURATION_URL = (practiceId) =>
	`/api/practices/${practiceId}/terminal/configuration`

export const removeReader = (practiceId, readerId) =>
	axiosClient.delete(REMOVE_READER_PATH(practiceId, readerId))

export const updateReader = (practiceId, readerId, updatedLabel) =>
	axiosClient.post(UPDATE_READER_URL(practiceId, readerId), {
		label: updatedLabel
	})

export const orderReader = (
	practiceId,
	{ clinicName, shippingMethod, consolidatorId, address, numberOfEthernetDocks, numberOfTerminals }
) =>
	axiosClient.post(ORDER_READER_URL(practiceId), {
		clinicName,
		shippingMethod,
		consolidatorId,
		address,
		numberOfEthernetDocks,
		numberOfTerminals
	})

export const createSimulatedReader = (practiceId, locationId) =>
	axiosClient.post(CREATE_READER_URL(practiceId), {
		location: locationId,
		registration_code: 'simulated-wpe'
	})

export const updateReaderConfiguration = (practiceId, params) =>
	axiosClient.post(UPDATE_READER_CONFIGURATION_URL(practiceId), {
		...params
	})
